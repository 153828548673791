<template lang="html" src="./pageRegisterValidate.template.vue"></template>

<style lang="scss" src="./pageRegisterValidate.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./pageRegisterValidate.i18n');

export default {
  name: 'PageRegisterValidate',
  components: { ApiErrorParser },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      step: 4,
      loading: false,
      defaultMessage: null,
      noRegistrationMessage: false,
      registrationInProgressMessage: null,
      registrationValidated: false,
    };
  },
  created() {
    this.confirmationValidateRegister();
  },
  methods: {
    confirmationValidateRegister() {
      this.loading = true;
      this.$apiInstance
        .validateBrandRegister(this.$route.params.registrationUUID, this.$route.params.tokenUUID)
        .then(
          () => {
            this.registrationValidated = true;
          }
        )
        .catch((error)=>{
          /**
           * ERROR ADD BRAND
           */
          if (error.body.message === 'No registration found') {
            this.noRegistrationMessage = true;
          } else if (error.body.message === 'Registration in progress') {
            this.registrationInProgressMessage = this.$t(
              'registrationInProgressMessage'
            );
          } else if (error.body.message === 'Unable to create the brand') {
            this.defaultMessage = this.$t('unableCreateBrand');
          } else {
            this.defaultMessage = ApiErrorParser.parse(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    retryToLoad() {
      this.defaultMessage = null;
      this.confirmationValidateRegister();
    },
  },
};
</script>
