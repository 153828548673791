var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-register-validate scrollbar"
  }, [_c('v-container', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "align-stretches fill-height no-gutters"
  }, [_c('v-col', {
    staticClass: "menuBackground white--text",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-img', {
    staticClass: "my-4",
    attrs: {
      "lazy-src": require("../../assets/logo.png"),
      "max-height": "240",
      "max-width": "300",
      "src": require("../../assets/logo.png"),
      "alt": "logo"
    }
  })], 1), _c('h1', {
    staticClass: "text-center white--text mt-10 mb-5 mx-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('thankToJoin')) + " ")])], 1), _c('v-col', [_c('v-stepper', {
    staticClass: "mx-2",
    attrs: {
      "flat": "",
      "alt-labels": ""
    },
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', {
    staticClass: "mt-6"
  }, [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 1,
      "color": "success",
      "step": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('userInformation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 2,
      "color": "success",
      "step": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('companyInformation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 3,
      "color": "success",
      "step": "3"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation')) + " ")]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "color": "success",
      "step": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('signin')) + " ")])], 1), _c('v-stepper-items', {
    staticClass: "col-12 col-md-8 offset-md-2"
  }, [_c('v-stepper-content', {
    staticClass: "mx-auto mt-6 pa-0",
    attrs: {
      "step": "4"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "150px"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "rotate": -90,
      "size": 120,
      "width": 8,
      "indeterminate": "",
      "color": "orange"
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('p', [_vm._v(_vm._s(_vm.$t("createdAccountLoading")))])], 1) : _vm.noRegistrationMessage ? _c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title mt-12 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("noRegistrationError")) + " ")]), _c('div', {
    staticClass: "svg-box"
  }, [_c('svg', {
    staticClass: "circular red-stroke"
  }, [_c('circle', {
    staticClass: "path",
    attrs: {
      "cx": "75",
      "cy": "75",
      "r": "50",
      "fill": "none",
      "stroke-width": "5",
      "stroke-miterlimit": "10"
    }
  })]), _c('svg', {
    staticClass: "cross red-stroke"
  }, [_c('g', {
    attrs: {
      "transform": "matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)"
    }
  }, [_c('path', {
    staticClass: "first-line",
    attrs: {
      "d": "M634.087,300.805L673.361,261.53",
      "fill": "none"
    }
  })]), _c('g', {
    attrs: {
      "transform": "matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)"
    }
  }, [_c('path', {
    staticClass: "second-line",
    attrs: {
      "d": "M634.087,300.805L673.361,261.53"
    }
  })])])]), _c('div', {
    staticClass: "mt-4 col-12 text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('noRegistrationMessage'))
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('p', [_vm._v(_vm._s(_vm.$t('alreadyAccount')))]), _c('v-btn', {
    staticClass: "white--text text-none mb-1",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "large": "",
      "color": "orange",
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("goToAccountBcm")) + " ")])], 1) : _vm.registrationInProgressMessage ? _c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title mt-12 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("registrationInProgress")) + " ")]), _c('div', {
    staticClass: "svg-box"
  }, [_c('svg', {
    staticClass: "circular yellow-stroke"
  }, [_c('circle', {
    staticClass: "path",
    attrs: {
      "cx": "75",
      "cy": "75",
      "r": "50",
      "fill": "none",
      "stroke-width": "5",
      "stroke-miterlimit": "10"
    }
  })]), _c('svg', {
    staticClass: "alert-sign yellow-stroke"
  }, [_c('g', {
    attrs: {
      "transform": "matrix(1,0,0,1,-615.516,-257.346)"
    }
  }, [_c('g', {
    attrs: {
      "transform": "matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)"
    }
  }, [_c('path', {
    staticClass: "line",
    attrs: {
      "d": "M634.087,300.805L673.361,261.53",
      "fill": "none"
    }
  })]), _c('g', {
    attrs: {
      "transform": "matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)"
    }
  }, [_c('circle', {
    staticClass: "dot",
    attrs: {
      "cx": "621.52",
      "cy": "316.126",
      "r": "1.318"
    }
  })])])])]), _c('div', {
    staticClass: "mt-4 col-12 text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('registrationInProgressMessage'))
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('p', [_vm._v(_vm._s(_vm.$t('alreadyAccount')))]), _c('v-btn', {
    staticClass: "white--text text-none mb-1",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "large": "",
      "color": "orange",
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("goToAccountBcm")) + " ")])], 1) : _vm.defaultMessage ? _c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title mt-12 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("errorOccurred")) + " ")]), _c('div', {
    staticClass: "svg-box"
  }, [_c('svg', {
    staticClass: "circular red-stroke"
  }, [_c('circle', {
    staticClass: "path",
    attrs: {
      "cx": "75",
      "cy": "75",
      "r": "50",
      "fill": "none",
      "stroke-width": "5",
      "stroke-miterlimit": "10"
    }
  })]), _c('svg', {
    staticClass: "cross red-stroke"
  }, [_c('g', {
    attrs: {
      "transform": "matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)"
    }
  }, [_c('path', {
    staticClass: "first-line",
    attrs: {
      "d": "M634.087,300.805L673.361,261.53",
      "fill": "none"
    }
  })]), _c('g', {
    attrs: {
      "transform": "matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)"
    }
  }, [_c('path', {
    staticClass: "second-line",
    attrs: {
      "d": "M634.087,300.805L673.361,261.53"
    }
  })])])]), _c('div', {
    staticClass: "mt-4 col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.defaultMessage) + " ")]), _c('div', {
    staticClass: "mt-4 col-12 text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('contactUs'))
    }
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-btn', {
    staticClass: "white--text text-none mb-1",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.retryToLoad
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")]), _vm._v(" " + _vm._s(_vm.$t("retry")) + " ")], 1)], 1) : _vm.registrationValidated ? _c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title mt-12 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("emailValidated")) + " ")]), _c('div', {
    staticClass: "svg-box"
  }, [_c('svg', {
    staticClass: "circular green-stroke"
  }, [_c('circle', {
    staticClass: "path",
    attrs: {
      "cx": "75",
      "cy": "75",
      "r": "50",
      "fill": "none",
      "stroke-width": "5",
      "stroke-miterlimit": "10"
    }
  })]), _c('svg', {
    staticClass: "checkmark green-stroke"
  }, [_c('g', {
    attrs: {
      "transform": "matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)"
    }
  }, [_c('path', {
    staticClass: "checkmark__check",
    attrs: {
      "fill": "none",
      "d": "M616.306,283.025L634.087,300.805L673.361,261.53"
    }
  })])])]), _c('div', [_c('p', [_vm._v(_vm._s(_vm.$t("messageClickToLogin")))]), _c('p', [_vm._v(_vm._s(_vm.$t('sendIdentif')))])]), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-btn', {
    staticClass: "white--text text-none mb-1",
    attrs: {
      "large": "",
      "color": "orange",
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("goToAccountBcm")) + " ")])], 1) : _vm._e()])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }